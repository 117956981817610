import styled from 'styled-components'

import { WrapPageItem, TableHeader, TableContent } from '../../mainStyles'

const WrapPostCheck = styled(WrapPageItem)`
       
`;

const TableHeaderPostCheck = styled(TableHeader)`
    grid-template-columns: 
    minmax(32px,55px) 
    minmax(96px,120px) 
    minmax(120px,145px) 
    minmax(136px,155px) 
    minmax(115px,140px)
    minmax(120px,145px)
    minmax(130px,155px) 1fr;
    .item-trans-time{
        display: flex;
        justify-content: end;
        padding-right: 25px;
    }
`;

const TableContentPostCheck = styled(TableContent)`
    
`;

export {
    WrapPostCheck,
    TableHeaderPostCheck,
    TableContentPostCheck
}