import React, { useMemo, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { WrapHistoryDetail } from './index.styles'
import { WrapPage, TitleSessionContainer } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import TransactionDetailBlock from './TransactionDetailBlock'
import CustomerDetailBlock from './CustomerDetailBlock'

// action
import { getDetailTransaction , clearDetailTransaction, clearListTransaction } from '../../../redux/transaction/action'
import { getDetailCheckPost, clearDetailCheckPost, getDetailCheckPostByCIF } from '../../../redux/postcheck/action'
import { disableLoading, enableLoading } from '../../../redux/loading/action'

let isCustomerDetailLoaded = false
let isCustomerHistoryLoaded = false

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

const HistoryDetails = () => {

    let query = useQuery();
    let customerId = query.get("customerId");
    let CIF = query.get("CIF")
    
    const dispatch = useDispatch()
    const { historyId } = useParams()

    const {detailPostCheck} = useSelector(state => state.postcheck)
    const {detailTransaction} = useSelector(state => ({
        detailTransaction: state.transaction.detailTransaction,
    }))

    useEffect(() => {
        initData()
        return () => {
            clearData()
        }
    },[])

    const initData = () => {
        const callbackInitData = () =>{
            if(isCustomerDetailLoaded && isCustomerHistoryLoaded){
                setTimeout(() => {
                    dispatch(disableLoading())
                },500)
            }
        }
        const callbackGetHistory = () => {
            isCustomerHistoryLoaded = true
            callbackInitData()
        }   

        const callbackGetDetail = () => {
            isCustomerDetailLoaded = true
            callbackInitData() 
            dispatch(getDetailTransaction(historyId, callbackGetHistory, true))
        }
        
        isCustomerDetailLoaded = false
        isCustomerHistoryLoaded = false
        dispatch(enableLoading()) 
        if(CIF?.length === 0 || customerId?.length === 0){
            dispatch(disableLoading())
        }
        if(customerId){
            dispatch(getDetailCheckPost({id: customerId}, callbackGetDetail))
            return
        }
        if(CIF){
            dispatch(getDetailCheckPost({customerId: CIF}, callbackGetDetail))
            return
        }
    }

    const clearData = () => {
        dispatch(clearDetailTransaction())
        dispatch(clearDetailCheckPost())
    }

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <WrapHistoryDetail>
                <TitleSessionContainer>
                    <p className='title'>Thông tin giao dịch</p>
                </TitleSessionContainer>
                <TransactionDetailBlock className="detail-transaction-block" data={detailTransaction} dataCIF={detailPostCheck}/>
                <TitleSessionContainer>
                    <p className='title' style={{marginTop: "24px"}}>Thông tin khách hàng</p>
                </TitleSessionContainer>
                <CustomerDetailBlock data={detailPostCheck} customerId={customerId}/>
            </WrapHistoryDetail>
        </WrapPage>
    )
}

export default HistoryDetails