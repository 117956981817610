import Axios from 'axios'
import * as types from './types'
import moment from "moment";

import {host} from '../../constant/host'

// api
import { enableLoading, disableLoading } from '../loading/action';
import { logoutAction } from '../login/action';
import { popNotify } from '../notifies/action';

// helpper
import autoDownloadFromBlob from '../../helper/autoDownloadFromBlob';
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from '../../helper/mapErrors';
import checkSessionLogin from '../../helper/checkSessionLogin';

const apiActivationFilter = `${host}/collate/customerEnable/filter`
const apiActivationDetail = `${host}/collate/customerEnable/details`
const apiActivationExport = `${host}/collate/customerEnable/export`

const headerDataRequest = {
    cId: "WebPortal",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

export const getListActivation = (
    condFilterItem = {
        customerId: "",
        cursorTimestamp: "",
        startTime: "",
        endTime: "",
        status: -1,
        filterFrom: "",
        filterSkip: 0,
        filterSize: 20
    },
    isScrolling = false,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => {

    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    if(!isScrolling) dispatch(enableLoading())
    let condFilter = {...condFilterItem}
    let dataRequest = {...headerDataRequest , data: condFilter}
    try {
        const res = await Axios.post(apiActivationFilter, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_LIST_ACTIVATION,
                payload: { 
                    listActivation: data.data.elements || [],
                    condFilter,
                    isScrolling
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_ACTIVATION_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
       dispatch(mapErrors(error, types.GET_LIST_ACTIVATION_ERROR))
       callbackError && callbackError()
    }
    if(!isScrolling) setTimeout(() => {
        dispatch(disableLoading())
    }, 500); 
    isCalling = false
}

export const clearListActivation = (callbackSuccess) => async (dispatch) => {
    dispatch({type: types.CLEAR_LIST_ACTIVATION})
    callbackSuccess && callbackSuccess()
}

export const getDetailActivation = (
    id,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    dispatch(enableLoading())
    
    try {
        const res = await Axios.get(`${apiActivationDetail}/${id}`)
        const { data } = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_DETAIL_ACTIVATION,
                detailActivation: data.data 
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_DETAIL_ACTIVATION_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_ACTIVATION_ERROR))
        callbackError && callbackError()
    }
    setTimeout(() => {
        dispatch(disableLoading())
    }, 500); 
    isCalling = false
}

export const clearDetailActivation = () => async (dispatch) => {
    dispatch({type: types.CLEAR_DETAIL_ACTIVATION})
}


export const resetCondfilterActivation = (callbackSuccess) => async dispatch => {
    dispatch({
      type: types.RESET_CONDFILTER_ACTIVATION,
    })
  
    callbackSuccess && callbackSuccess()
}

export const exportActivation = (
    dataActivation = {
        customerId: "",
        reviewStatus: "",
        startTime: "",
        endTime: ""
    },
    callbackSuccess,
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) {
        dispatch(logoutAction())
        return
    }
    let data = {...dataActivation}
    let dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiActivationExport, dataRequest, {
            responseType: 'blob',
        })
        const {data} = res
        autoDownloadFromBlob(data, 'activation')
        
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_ACTIVATION_ERROR))
    }
}