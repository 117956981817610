import React, { useState, useEffect } from "react";

// styles
import { Bound } from "./index.styles";

// help
import { convertDateTime } from "../../../../helper/convertDateTime";

// container
import FaceZoom from "../../../control/FaceZoom";

//host
import { apiGetAvatarV2 } from "../../../../constant/host";

// image
import IcAvatarDefault from "../../../../assets/images/IcAvatarDefault";

// data
import { dataActivationReason } from "../../../../data/dataActivationReason";

export default function ActivationItem(props) {
  const { className, item, refList, index , onClick} = props;

  const [isErrImg, setIsErrImg] = useState(false);

  let classNameStatus = "";
  let textStatus = "";
  switch (item?.status) {
    case 0:
      classNameStatus = "status red";
      textStatus = "Thất bại";
      break;
    case 1:
      classNameStatus = "status green";
      textStatus = "Thành công";
      break;
  }

  useEffect(() => {
    setIsErrImg(false);
  }, [item?.imageId]);

  return (
    <Bound  className={className} onClick={onClick}>
      <div className="item-check-nomal">
        {index < 9 ? `0${index + 1}` : index + 1}
      </div>
      <div className="item-check-nomal" title={item?.customerId}>
        {item?.customerId ? item?.customerId : "-"}
      </div>
      {!isErrImg && item?.imageId ? (
        <>
          <FaceZoom
            className="item-check-img"
            url={apiGetAvatarV2(item?.imageId)}
            refList={refList}
            onError={() => setIsErrImg(true)}
          />
        </>
      ) : (
        <IcAvatarDefault />
      )}

      <div className="item-check-nomal">
        {item?.createAt
          ? convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm")
          : ""}
      </div>
      <div className="item-check-nomal" title={item?.deviceName}>
        {item?.deviceName ? item?.deviceName : "-"}
      </div>
      <div className="item-check-nomal" title={item?.deviceOS}>
        {item?.deviceOS ? item?.deviceOS : "-"}
      </div>
      <div className={classNameStatus}>{textStatus}</div>
      <div className="item-check-nomal reason-text" 
        // title={item?.statusCode !== 0 && dataActivationReason[item?.statusCode] ? dataActivationReason[item?.statusCode] : "-"}
        title={item?.statusDesc}>
        {/* {item?.statusCode !== 0 && dataActivationReason[item?.statusCode] ? dataActivationReason[item?.statusCode] : "-"} */}
        {item?.statusDesc ? item?.statusDesc : "-"}
      </div>
    </Bound>
  );
}
