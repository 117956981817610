import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// component
import Login from "./components/Login";
import Main from "./components/Main";
import CustomerDetails from "./components/Main/CustomerDetails";
import HistoryDetails from "./components/Main/HistoryDetails";
import PostCheck from "./components/Main/PostCheck";
import TransactionHistory from "./components/Main/TransactionHistory"
import TransactionHistoryV2 from "./components/Main/TransactionHistoryV2";
import TransactionHistoryDetailsV2 from "./components/Main/TransactionHistoryDetailsV2";
import PrivateRoute from "./components/PrivateRoute";
import Activation from "./components/Main/Activation";
import ActivationDetails from "./components/Main/ActivationDetails";

// constant
import {
  customerDetails,
  historyDetails,
  activationDetails,
  main,
  postCheck,
  activation,
  transactionHistory
} from "./constant/routes";

// enviroment
import { env, webVer, WEB_VER_1, WEB_VER_2 } from "./constant/enviromentTypes";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route
        path={`/${main}/*`}
        element={
          <PrivateRoute>
            <Main />
          </PrivateRoute>
        }
      >
        {
          webVer === WEB_VER_1 &&
          <>
            <Route
              path={postCheck}
              element={
                <PrivateRoute>
                  <PostCheck />
                </PrivateRoute>
              }
            />
            <Route
              path={transactionHistory}
              element={
                <PrivateRoute>
                  <TransactionHistory />
                </PrivateRoute>
              }
            />
            <Route
              path={`${postCheck}/${customerDetails()}`}
              element={
                <PrivateRoute>
                  <CustomerDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={`${postCheck}/${historyDetails()}`}
              element={
                <PrivateRoute>
                  <HistoryDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={`${transactionHistory}/${historyDetails()}`}
              element={
                <PrivateRoute>
                  <HistoryDetails />
                </PrivateRoute>
              }
            />
          </>
        }
        {
          webVer === WEB_VER_2 &&
          <>
            <Route
              path={activation}
              element={
                <PrivateRoute>
                  <Activation />
                </PrivateRoute>
              }
            />
            <Route
              path={`${activation}/${activationDetails()}`}
              element={
                <PrivateRoute>
                  <ActivationDetails />
                </PrivateRoute>
              }
            />
            <Route
              path={transactionHistory}
              element={
                <PrivateRoute>
                  <TransactionHistoryV2 />
                </PrivateRoute>
              }
            />
            <Route
              path={`${transactionHistory}/${historyDetails()}`}
              element={
                <PrivateRoute>
                  <TransactionHistoryDetailsV2 />
                </PrivateRoute>
              }
            />
          </>
        }
        
        {
          webVer === WEB_VER_1 ?
          <Route path="*" element={<Navigate to={`/${main}/${postCheck}`} />} />
          :
          <Route path="*" element={<Navigate to={`/${main}/${activation}`} />} />
        }
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default App;
