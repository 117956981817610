import React, { useState, useEffect, useMemo } from "react";
import { useLocation } from 'react-router-dom'

// css
import { DeltailBlockContainer } from "../../../mainStyles";

// component
import Portal from "../../../control/Portal";
import PopupConfirmUpdateFace from "../../../Popup/PopupConfirmUpdateFace";
import Button from "../../../control/Button";

//helper
import { convertDateTime } from "../../../../helper/convertDateTime";
import { checkTypeTransaction } from "../../../../helper/checkTypeTransaction";

//images
import IcUpdateFace from "../../../../assets/images/IcUpdateFace";
import AvatarDefault from "../../../../assets/images/AvatarDefault";

// host
import { apiGetTransactionAvatarV2 } from "../../../../constant/host";

export default function TransactionDetailBlock({ data, className }) {

  const [isErrImg, setIsErrImg] = useState(false);

  let classNameStatus = "";
  let textStatus = "";
  
  switch (data?.status) {
    case 0: {
      classNameStatus = "status red";
      textStatus = "Thất bại";
      break;
    }
    case 1: {
      classNameStatus = "status light-green";
      textStatus = "Thành công";
      break;
    }
    default: {
      classNameStatus = "status"
      textStatus = "-"
    }
  }

  useEffect(() => {
    setIsErrImg(false);
  }, []);

  return (
    <DeltailBlockContainer className={className}>
      <div className="detail-container">
        <div className="face-block"
          style={data?.status===0?{borderColor:"#D62A00"}:{borderColor:"#008C44"}}>
          {
            (data?.type === 1 || !data?.imageId) ?
            <AvatarDefault />
            :
            isErrImg ? 
              <AvatarDefault />
              :
              <img
                src={apiGetTransactionAvatarV2(data?.imageId, data?.type)}
                alt="avatar"
                onError={() => setIsErrImg(true)}
              />
          }
          
        </div>
        <div className="info-block transaction-info">
          {/* transactionId */}
          <div className="info-block-item">
            <p className="p-value" title={`#${data?.transactionId}`}>
              {
                data?.transactionId ?
                `#${data?.transactionId}`
                :
                "-"
              }
            </p>
            <p className="p-title">Mã giao dịch</p>
          </div>
          {/* customerId */}
          <div className="info-block-item">
            <p className="p-value" title={data?.customerId}>
              {data?.customerId || "-"}
            </p>
            <p className="p-title">Số CIF</p>
          </div>
          {/* createAt */}
          <div className="info-block-item">
            <p className="p-value">
              {data?.createAt
                ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm")
                : ""}
            </p>
            <p className="p-title">Thời gian giao dịch</p>
          </div>
          {/* type */}
          <div className="info-block-item">
            <p className="p-value">
              {checkTypeTransaction(data?.type)?.textType}
            </p>
            <p className="p-title">Loại xác thực</p>
          </div>
          {/* deviceName */}
          <div className="info-block-item">
            <p className="p-value">{data?.deviceName || "-"}</p>
            <p className="p-title">Tên thiết bị</p>
          </div>
          {/* deviceOS */}
          <div className="info-block-item">
            <p className="p-value">{data?.deviceOS || "-"}</p>
            <p className="p-title">Hệ điều hành</p>
          </div>
          {/* status */}
          <div className="info-block-item">
            <p className={`p-value ${classNameStatus}`}>{textStatus}</p>
            <p className="p-title">Trạng thái giao dịch</p>
          </div>
          {/* statusDesc */}
          <div className="info-block-item reason">
            <p className="p-value" title={data?.statusDesc}>{data?.statusDesc || "-"}</p>
            <p className="p-title">Nguyên nhân</p>
          </div>
        </div>
      
      </div>   
    </DeltailBlockContainer>
  );
}
