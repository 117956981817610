import styled from 'styled-components'

import { WrapPageItem } from '../../mainStyles'

const WrapHistoryDetail = styled(WrapPageItem)`
    .detail-transaction-block{
        border-radius: 6px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        padding: 24px;
        width: calc(100% - 48px);
        .detail-container{
            padding: 0;
            .transaction-info, .activation-info{
                display: grid;
                grid-template-columns: repeat(3, 270.5px);
                .info-block-item{
                    justify-content: end;
                }
                .reason{
                    .p-value{
                        white-space: unset;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }
            }
            .transaction-info{
                .reason{
                    grid-column: 2 / span 2;
                }
            }
            .activation-info{
                .reason{
                    grid-column: unset;
                }
            }
        }
    }
    .success{
        background: #F1FCF6;
    }
    .fail{
        background: #FFF1EF;
    }
    .title{
        margin-bottom: 16px;
    }
`;

export {
    WrapHistoryDetail
}