import React, { useEffect, useMemo } from "react";
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// css
import { WrapBreadcrumbAndTool, WrapBreadcrumb } from "./index.styles";

// constant
import {
  customerDetails,
  activationDetails,
  historyDetails,
  main,
  postCheck,
  activation,
  transactionHistory
} from "../../../constant/routes";

//images
import IcArrowRightBlack from "../../../assets/images/IcArrowRightBlack";
import LogoPostCheck from "../../../assets/images/LogoPostCheck";

// action
import { countPostCheck, getDetailCheckPost } from "../../../redux/postcheck/action";
import { countTransaction, getDetailTransaction } from "../../../redux/transaction/action";

// helpper
import numberFormatToThousand from "../../../helper/numberFormatToThousand";
import { webVer, WEB_VER_1 } from "../../../constant/enviromentTypes";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const BreadcrumbItem = ({ arrPath }) => {
  return (
    <WrapBreadcrumb>
      {arrPath.map((pathItem, index) => (
        <React.Fragment key={pathItem.value}>
          <p
            onClick={pathItem.onClick}
            style={pathItem.onClick ? { cursor: "pointer" } : {}}
          >
            {pathItem.value}
          </p>
          {arrPath.length - 1 !== index && (
            <IcArrowRightBlack className="icon-text" />
          )}
        </React.Fragment>
      ))}
    </WrapBreadcrumb>
  );
};

const PostCheckBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Hậu kiểm"
        }
      ]} />
    </>
  );
};

const CustomerDetailBread = () => {

  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Hậu kiểm",
            onClick: () => {
              navigate(`/${main}/${postCheck}`)
            }
          },
          {
            value: "Chi tiết khách hàng"
          },
        ]}
      />
    </>
  );
};

const ActivationBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Kích hoạt"
        }
      ]} />
    </>
  );
};

const ActivationDetailBread = () => {

  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Kích hoạt",
            onClick: () => {
              navigate(`/${main}/${activation}`)
            }
          },
          {
            value: "Chi tiết giao dịch"
          },
        ]}
      />
    </>
  );
};

const CustomerDetailBreadHistory = () => {

  const navigate = useNavigate()

  let query = useQuery();
  let customerId = query.get("customerId");

  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Hậu kiểm",
            onClick: () => {
              navigate(`/${main}/${postCheck}`)
            }
          },
          {
            value: "Chi tiết khách hàng",
            onClick: () => {
              navigate(`/${main}/${postCheck}/${customerDetails(customerId)}`)
            }
          },
          {
            value: "Chi tiết giao dịch"
          },
        ]}
      />
    </>
  );
};

const TransactionHistoryBread = () => {
  return (
    <>
      <BreadcrumbItem arrPath={[
        {
          value: "Lịch sử giao dịch"
        }
      ]} />
    </>
  );
};

const HistoryDetailBread = () => {
  const navigate = useNavigate()
  return (
    <>
      <BreadcrumbItem
        arrPath={[
          {
            value: "Lịch sử giao dịch",
            onClick: () => {
              navigate(`/${main}/${transactionHistory}`)
            }
          },
          {
            value: "Chi tiết giao dịch",
          },
        ]}
      />
    </>
  );
};

const Breadcrumb = () => {
  
  let query = useQuery();
  let customerId = query.get("customerId");

  const dispatch = useDispatch()

  const {totalPostCheck} = useSelector(state => state.postcheck)
  const { totalListTransaction } = useSelector(state => state.transaction)


  const callbackSuccess = () => { }
  const callbackError = () => { }

  useEffect(() => {
    if(webVer === WEB_VER_1){
      dispatch(countPostCheck(callbackSuccess, callbackError))
      if(customerId){
        dispatch(countTransaction(customerId, callbackSuccess, callbackError))
      }
    }
  }, [])

  return (
    <WrapBreadcrumbAndTool>
      <div className="wrap-breadcrumb-and-tool">
        <div className="wrap-left-container">
          <div className="logo-post-check">
            <LogoPostCheck />
          </div>
          {useMatch(`/${main}/${postCheck}`) && <PostCheckBread />}
          {useMatch(`/${main}/${postCheck}/${customerDetails()}`) && <CustomerDetailBread />}
          {useMatch(`/${main}/${postCheck}/${historyDetails()}`) && <CustomerDetailBreadHistory />}
          {useMatch(`/${main}/${activation}`) && <ActivationBread />}
          {useMatch(`/${main}/${activation}/${activationDetails()}`) && <ActivationDetailBread />}
          {useMatch(`/${main}/${transactionHistory}`) && <TransactionHistoryBread />}
          {useMatch(`/${main}/${transactionHistory}/${historyDetails()}`) && <HistoryDetailBread />}
        </div>
        <div className="wrap-right-content">
          {useMatch(`/${main}/${postCheck}`) && `${numberFormatToThousand(totalPostCheck)} khách hàng`}
          {useMatch(`/${main}/${postCheck}/${customerDetails()}`) && `${numberFormatToThousand(totalPostCheck)} khách hàng`}
          {useMatch(`/${main}/${transactionHistory}/${historyDetails()}`) && customerId ? `${numberFormatToThousand(totalListTransaction)} giao dịch` : ""}
        </div>
      </div>
    </WrapBreadcrumbAndTool>
  );
};

export default Breadcrumb;
