import styled from 'styled-components'

const OverlayFullScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    min-width: 100vw;
    width: 1280px;
    height: 940px;
    background: ${ props => props.notUseOverlay ? 'transparent' : '#22222280' };
    z-index: 6;

    .wrap-inner-screen {
        width: 1280px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`


const WrapPage = styled.div`
    display: flex;
    flex-direction: column;
    background: #F6F6F6;
`

const WrapPageItem = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0 25px;
    width: calc(1280px - 100px);
    /* height: 100%; */
    flex-grow: 1;
    box-sizing: border-box;
`

const TableHeader = styled.div`    
    display: grid;
    grid-gap: 5px; 
    padding: 0 15px;
    box-sizing: border-box;
    width: 100%;
    .table-header {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #828282;
    }

`

const TableContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    .wrap-empty {
        flex-grow: 1;
        display: flex;
        align-items: center;
    }
    .wrap-list-trans {
        width: calc(100% + 10px);
        flex-grow: 1;
        overflow: auto;
        margin-top: 11px;
        position: relative;
        z-index: 0;
        margin-left: 10px;
        .list-trans {
            width: calc(100% - 10px);
            position: absolute;
            display: flex;
            flex-direction: column;
            .history-item{
                margin-bottom: 6px;
            }
        }
    }
`

const TitleSessionContainer = styled.div`
    display: flex;
    .title{
        font-family: 'Manrope';
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #212123;
    }
`

const DeltailBlockContainer = styled.div`
    width: calc(100% - 32px);
    // height: 216px;
    background: #FFFFFF;
    box-shadow: inset 0px -1px 0px rgba(146, 168, 189, 0.25);
    border-radius: 6px 6px 0px 0px;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .detail-container{
        padding: 8px 0 8px 8px;
        display: flex;
        flex-direction: row;
        width: 100%;
        .face-block{
            width: 198px;
            height: 198px;
            border: 1px solid #008C44;
            border-radius: 8px;
            margin-right: 24px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .info-block{
            display: grid;
            grid-gap: 24px;
            width: calc(100% - 224px);
            height: fit-content;
            .info-block-item{
                display: flex;
                flex-direction: column;
            }
            p{
                font-family: "Manrope";
                font-style: normal;
            }
            .p-value{
                min-height: 20px;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: #212123;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }
            .p-title{
                font-weight: normal;
                font-size: 11px;
                line-height: 15px;
                color: #828282;
            }
            .cause{
                grid-column: 1 / span 3;
                .p-value{
                    max-height: 40px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    white-space: unset;
                    word-break: break-word;
                }
                
            }
            .status{
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
            }
            .green{
                color: #008C44;
            }
            .grey{
                color: #B7B7B7;
            }
            .red{
                color: #D62A00;
            }
            .light-green{
                color: #3CC13B;
            }
        }
        .post-check-info{
            grid-template-columns: 200px 300px 1fr;
        }
        .history-info{
            grid-template-columns: 200px 170px 200px auto;
        }
        .customer-info{
            grid-template-columns: 200px 290px auto;
        }
        .activation-info{
            grid-template-columns: repeat(3, 1fr);
        }
    }
    .btn-container{
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.01em;
        color: #FFFFFF;
        border-radius: 4px;
        svg{
                margin-right: 4px;
            }
        .btn-post-check{
            width: 105px;
            height: 34px;
            background: #008C44;
            
        }
        .btn-update-face{
            width: 169px;
            height: 34px;
            background: #008C44;
        }
        
    }
`

export {
    OverlayFullScreen,
    WrapPage,
    WrapPageItem,
    TableHeader,
    TableContent,
    TitleSessionContainer,
    DeltailBlockContainer
}