import styled, { css } from "styled-components";

const WrapFilterTab = styled.div`
    display: flex;
    width: 100%;
    height: 66px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    .filter-container{
        width: calc(1280px - 100px - 50px);
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        display: grid;
        grid-template-columns: repeat(4, auto) 1fr repeat(2, auto);
        grid-column-gap: 10px;
        align-items: center;
        padding: 0 25px;
        color: #212123;
        
        .filter-item{
            width: 180px;
            margin-right: 6px;
            &::placeholder{
                color: #828282;
            }
        }
        .list-dropdown{
            width: 180px;
            margin-right: 6px;
            .wrap-dropdown{
                width: 180px;
            }
        }
        .input-search{
            width: 244px;
            height: 44px;
            margin: 0;
            input {
                font-family: "Manrope";
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 20px;
                color: #828282;
                border-radius: 8px;
                border: 0.5px solid #828282;
                box-sizing: border-box;
                :focus{
                    border: 0.5px solid #008C44;
                }
            }
        }
        .btn{
            width: 105px;
            height: 34px;
            border-radius: 4px;
            border: none;
        }
        .btn-success{
            color: #FFFFFF;
            background-color: #008C44;
        }
        .btn-disable{
            color: #B7B7B7;
            background-color: #F6F6F6;
            cursor: auto;
        }
    }
`
const extendStylesInputSearch = css`
    border: 0.5px solid #828282;
    box-sizing: border-box;
    border-radius: 8px;
    width: 244px;
    height: 44px;

    input {
        font-family: "Manrope";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        color: #828282;
    }
`

export {
    WrapFilterTab,
    extendStylesInputSearch
}