import React, { useMemo, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { WrapHistoryDetail } from './index.styles'
import { WrapPage, TitleSessionContainer } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import TransactionDetailBlock from './TransactionDetailBlock'
import ActivationDetailBlock from './ActivationDetailBlock'

// action
import { getDetailTransaction , clearDetailTransaction, clearListTransaction } from '../../../redux/transaction/action'

const TransactionHistoryDetailsV2 = () => {

    const dispatch = useDispatch()
    const { historyId } = useParams()

    const {detailTransaction} = useSelector(state => ({
        detailTransaction: state.transaction.detailTransaction,
    }))

    useEffect(() => {
        if(historyId && historyId.length > 0) {
            dispatch(getDetailTransaction(historyId, ()=>{}, ()=>{}, false, true))
        }
        return () => {
            clearData()
        }
    },[])

    const clearData = () => {
        dispatch(clearDetailTransaction())
    }

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <WrapHistoryDetail>
                <TitleSessionContainer>
                    <p className='title'>Thông tin giao dịch</p>
                </TitleSessionContainer>
                <TransactionDetailBlock 
                    className={`detail-transaction-block ${detailTransaction?.details?.status === 1 ? "success" : "fail"}`} 
                    data={detailTransaction?.details}
                />
                <TitleSessionContainer>
                    <p className='title' style={{marginTop: "24px"}}>Thông tin kích hoạt</p>
                </TitleSessionContainer>
                <ActivationDetailBlock 
                    className="detail-transaction-block"
                    data={detailTransaction?.enableDetails}/>
            </WrapHistoryDetail>
        </WrapPage>
    )
}

export default TransactionHistoryDetailsV2