import React from 'react'

// data
import { listStatusType } from '../../../../../data/dataActivationStatus'

//images
import IcFlagBlue from '../../../../../assets/images/IcFlagBlue'

//component
import InputDropdown from '../../../../control/InputDropdown'


const iconStore = {
    icon: IcFlagBlue,
    iconActive: IcFlagBlue,
    iconError: IcFlagBlue,
}


const FilterStatus = ({ handleGetValueDropdown, defaultKey, className }) => {

    const _handleSetValueDropdown = (val) => {
        handleGetValueDropdown && handleGetValueDropdown(val)
    }

    return (
        <InputDropdown
            className={className}
            iconInput={iconStore}
            getValueDropdown={_handleSetValueDropdown}
            dataFilterOption={listStatusType}
            objChoice={listStatusType.find((item)=>item.key === defaultKey) || null}
        />
    )
}

export default FilterStatus
