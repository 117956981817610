import styled from 'styled-components'

const WrapBreadcrumb = styled.div`
    display: flex;
    align-items: center;

    p {
        font-weight: 500;
        font-size: 20px;
        font-family: Manrope;
        font-style: normal;
        font-size: 15px;
        line-height: 20px;
    }

    & > p:first-child {
        color: #212123;
        font-weight: 500; 
    }

    & > p:last-child {
        font-weight: normal;
        color: #828282;
    }

    .icon-text {
        padding: 10px;
    }
`;

const WrapBreadcrumbAndTool = styled.div`
    height: 88px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    .wrap-breadcrumb-and-tool{
        width: calc(1280px - 100px);
        box-sizing: border-box;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        letter-spacing: -0.01em;
        color: #313131;
        .wrap-left-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            .logo-post-check{
                // width: 40px;
                // height: 40px;
                // // background: url('../../../assets/images/LogoPostCheck');
                margin-right: 12px;
            }
            .breadcrumb-title{
                font-family: Manrope;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                display: flex;
                color: #212123;
            }
        }
        .wrap-right-content{
            display: flex;
            flex-direction: row;
            font-family: Manrope;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 33px;
            
        }
    }
`;

export {
    WrapBreadcrumbAndTool,
    WrapBreadcrumb
}