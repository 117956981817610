import moment from 'moment'

// enviroment & web version
import { env, webVer, DEV_ENV, QC_ENV, PROD_ENV, WEB_VER_1, WEB_VER_2 } from './enviromentTypes'

export const { host } = (() => {
    switch (webVer) {
        case WEB_VER_1:{
            switch (env) {
                case DEV_ENV:
                    return { 
                        host: "https://ocb-faceotp-dev.wee.vn/api"
                    }
                case PROD_ENV:
                    return { 
                        host: "https://ocb-faceotp.wee.vn/api"
                    }    
                default:
                    return { 
                        host: "https://ocb-faceotp-dev.wee.vn/api"
                    }
            }
        }
            break;
        case WEB_VER_2:{
            switch (env) {
                case DEV_ENV:
                    return { 
                        host: "https://doisoat-dev.wee.vn/api"
                    }
                case PROD_ENV:
                    return { 
                        host: "https://doisoat.wee.vn/api"
                    }    
                default:
                    return { 
                        host: "https://doisoat-dev.wee.vn/api"
                    }
            }
        }
            break;
        default:
            return { 
                host: "https://ocb-faceotp-dev.wee.vn/api"
            }
    }
})()

export const buildVersion = "06032023"
export const qcVersion = "none"
export const branch = "feature/dev-v1.1"

console.log(`website version: ${webVer}`)
console.log(`environment: ${env}`)
console.log(`build: ${buildVersion}`)
console.log(`qc build: ${qcVersion}`)
console.log(`branch: ${branch}`)

export const apiGetFace = (faceId) => `${host}/manage/transaction/photo?faceId=${faceId}&time=${moment().unix()}`

export const apiGetAvatar = (id) => `${host}/manage/customer/avatar?id=${id}&time=${moment().unix()}`

export const apiGetAvatarV2 = (id) => `${host}/collate/customerEnable/image/${id}`

export const apiGetTransactionAvatarV2 = (id, type) => `${host}/collate/transaction/image/${type}/${id}`

export const apiGetTransActivationAvatarV2 = (id) => `${host}/collate/transaction/enableImage/${id}`