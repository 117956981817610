// enviroment
export const DEV_ENV = "dev"
export const QC_ENV = "qc"
export const PROD_ENV = "prod"

// web version
export const WEB_VER_1 = "ver1"
export const WEB_VER_2 = "ver2"

export const env = process.env.REACT_APP_BUILD_ENV || DEV_ENV
export const webVer = process.env.REACT_APP_SERVER_VERSION || WEB_VER_1