import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import moment from "moment";

//data
import dataMenu from "../../../data/dataSideMenu";
import LogoCraftWeedigital from "../../../assets/images/LogoCraftWeedigial";

// css
import { WrapSideMenu } from "./index.styles";

// action
import { getListCheckPost, resetListCheckPost } from "../../../redux/postcheck/action";
import { getListTransaction, getListTransactionV2, resetListTransaction } from "../../../redux/transaction/action";
import { clearScroll } from "../../../redux/scrolling/action";

// routes
import { postCheck, transactionHistory } from '../../../constant/routes'

// constant
import { webVer, WEB_VER_1, WEB_VER_2 } from "../../../constant/enviromentTypes";

const SideMenu = () => {
  const dispatch = useDispatch();

  const defaultStartDate = moment().utc().startOf("month").toDate();
  const defaultEndDate = moment(new Date()).endOf("day").toDate();

  const callbackResetListCheckPostSuccess = () => {
        dispatch(getListCheckPost({
            startTime: defaultStartDate,
            endTime: defaultEndDate,
            reviewStatus: -1,
            customerId: "",
            filterFrom: "",
            filterSize: 20,
            filterSkip: 0,
        }))
  };

  const callbackResetListTransactionSuccess = () => {
    let dataFilter = {
      id: "",
      customerId: "",
      transactionId: "",
      type: -1,
      paymentStatus: -1,
      startTime: defaultStartDate,
      endTime: defaultEndDate,
      filterFrom: "",
      filterSkip: 0,
      filterSize: 20
    }
    switch (webVer) {
      case WEB_VER_1:{
        dispatch(getListTransaction(dataFilter))
      }
        break;
      case WEB_VER_2:{
        dispatch(getListTransactionV2(dataFilter))
      }
        break;
      default:
        break;
    }
  }


  const _handleClick = (key) => {
    if (!key) return;
    switch (key) {
      case postCheck:{
          dispatch(clearScroll({ key: postCheck }));
          dispatch(resetListCheckPost(callbackResetListCheckPostSuccess));
        }
        break;
      case transactionHistory:{
          dispatch(clearScroll({ key: transactionHistory }));
          dispatch(resetListTransaction(callbackResetListTransactionSuccess));
        }
        break;

      default:
        break;
    }
  };

  return (
    <WrapSideMenu>
      <div className="wrap-menu">
        {dataMenu.map((item, index) => {
          if(!item.webVer.includes(webVer)) return
          return(
            <NavLink
              to={item.path}
              key={index}
              onClick={() => _handleClick(item.key)}
              className={({ isActive }) =>
                  isActive ? "link-item selected" : "link-item "
              }
            >
              <item.IconActive className="icon active" />
              <item.Icon className="icon" />
              <p>{item.name}</p>
            </NavLink>
          )
        })}
      </div>
      <LogoCraftWeedigital />
    </WrapSideMenu>
  );
};

export default SideMenu;
