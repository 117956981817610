import React from 'react'

export default function AvatarDefault(props) {
  return (
    <svg {...props} width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="199" height="199" rx="7.5" fill="#F6F6F6"/>
        <path d="M150 156.25V143.75C150 137.12 147.19 130.761 142.19 126.072C137.189 121.384 130.406 118.75 123.333 118.75H76.6667C69.5942 118.75 62.8115 121.384 57.8105 126.072C52.8095 130.761 50 137.12 50 143.75V156.25" stroke="#B7B7B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M100 93.75C113.807 93.75 125 82.5571 125 68.75C125 54.9429 113.807 43.75 100 43.75C86.1929 43.75 75 54.9429 75 68.75C75 82.5571 86.1929 93.75 100 93.75Z" stroke="#B7B7B7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <rect x="0.5" y="0.5" width="199" height="199" rx="7.5" stroke="#008C44" strokeLinecap="round"/>
    </svg>

  )
}
