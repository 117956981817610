import React, { useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// css
import { WrapPostCheck, TableHeaderPostCheck, TableContentPostCheck } from './index.styles'
import { WrapPage } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import FilterTab from './FilterTab'
import EmptyDataControl from '../../control/EmptyDataControl'
import TransactionItem from './TransactionItem'

// hook
import useScrollEnd from '../../../hook/useScrollEnd'

// image 
import IcNodata from '../../../assets/images/IcNoData'

// route
import { main, historyDetails } from '../../../constant/routes'

// keys
import { transactionHistory } from '../../../constant/keys'

// action
import { clearListTransaction, getListTransaction } from '../../../redux/transaction/action'
import { setScroll, clearScroll } from '../../../redux/scrolling/action'

const dataHeader = [
    "Mã giao dịch",
    "Loại xác thực",
    "Số CIF",
    "Ngày giờ giao dịch",
    "Trạng thái"
]

let timer = null

const TransactionHistory = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const listRef = useRef(null)

    // const [listTransaction, setListTransaction] = useState([])

    const {
        listTransaction, condFilterList, 
        transactionHistoryScroll } = useSelector(state => ({
        listTransaction: state.transaction.listTransaction,
        condFilterList: state.transaction.condFilterList,
        transactionHistoryScroll: state.scrolling.transactionHistory
    }))
    
    const callbackSuccess = () => {
        if(listRef.current){
            listRef.current.scrollTop = transactionHistoryScroll.scrollTop
        }
    }
    const callbackError = () => {}


    useEffect(() => {
        dispatch(clearListTransaction())
        dispatch(clearScroll({key: 'transaction-history'}))
        let newCondFilter = {
            ...condFilterList,
            filterFrom: "",
            filterSkip: 0,
            filterSize: condFilterList.filterSkip + condFilterList.filterSize
        }
        dispatch(getListTransaction(
            newCondFilter,
            false,
            callbackSuccess,
            callbackError))
        return(
            clearTimeout(timer)
        )
    },[])

    const _handleScroll = (scrollTop) => {
        if(timer) clearTimeout(timer)
        timer = setTimeout(() => {
            dispatch(setScroll({key: transactionHistory, scrollTop}))
        }, 500);
    }

    useScrollEnd({scrollEleRef: listRef, callback: (callback) => {
        dispatch(getListTransaction(
            {
                ...condFilterList,
                filterFrom: listTransaction[listTransaction.length - 1].id,
                filterSkip: listTransaction.length,
                filterSize: 20
            }, 
        true, callback))
    }, dependencies:[listTransaction], dependenciesScroll:[listTransaction?.length], callBackScroll: _handleScroll})


    const _handleDetailTransactionBtn = (historyId , CIF) => {
        navigate(`/${main}/${transactionHistory}/${historyDetails(historyId)}?CIF=${CIF}`)
    }

    const tableHeader = useMemo(() => (
        listTransaction.length > 0 &&
            dataHeader.map((item, i) => (
                <div className="table-header" key={i}>
                    {item}
                </div>
            ))
    ), [JSON.stringify(listTransaction)])

    const tableContent = useMemo(() => (
        listTransaction.length === 0 ?
            <div className="wrap-empty">
                <EmptyDataControl 
                    IconNonData={<IcNodata className='ic-no-data' />}
                    labelDes='Hiện tại chưa có giao dịch được tải lên'/>
            </div>
            :
            <div className="wrap-list-trans" ref={listRef}>
                <div className="list-trans">
                {
                    listTransaction.map((item, i) => (
                        <TransactionItem key={i} index={i} className='history-item'
                            item={item} refList={listRef} onClick={
                                (e) => {
                                    _handleDetailTransactionBtn(item.id , item.customerId)
                                }
                            }/>
                    ))
                }
                </div>
            </div>
    ), [JSON.stringify(listTransaction)])

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <FilterTab />
            <WrapPostCheck>
                <TableHeaderPostCheck>
                    {tableHeader}
                </TableHeaderPostCheck>
                <TableContentPostCheck>
                    {tableContent}
                </TableContentPostCheck>
            </WrapPostCheck>
        </WrapPage>
    )
}

export default TransactionHistory