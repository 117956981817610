import React, { useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// css
import { WrapActivation, TableHeaderActivation, TableContentActivation } from './index.styles'
import { WrapPage } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import FilterTab from './FilterTab'
import EmptyDataControl from '../../control/EmptyDataControl'
import ActivationItem from './ActivationItem'

// hook
import useScrollEnd from '../../../hook/useScrollEnd'

// image 
import IcNodata from '../../../assets/images/IcNoData'
import { main, activation, activationDetails } from '../../../constant/routes'

// action
import { getListActivation } from '../../../redux/activation/action'
import { clearListTransaction } from '../../../redux/transaction/action'
import { setScroll, clearScroll } from '../../../redux/scrolling/action'

const dataHeader = [
    "STT",
    "Số CIF",
    "Hình ảnh kích hoạt",
    "Thời gian kích hoạt",
    "Tên thiết bị",
    "Hệ điều hành",
    "Trạng thái kích hoạt",
    "Nguyên nhân"
]

let timer = null

const Activation = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const listRef = useRef(null)

    const {listActivation, condFilter, activationScroll } = useSelector(state => ({
        listActivation: state.activation.listActivation,
        condFilter: state.activation.condFilter,
        activationScroll: state.scrolling.activation
    }))
    const callbackSuccess = () => {
        if(listRef.current){
            listRef.current.scrollTop = activationScroll.scrollTop
        }
    }
    const callbackError = () => {}


    useEffect(() => {
        dispatch(clearListTransaction())
        dispatch(clearScroll({key: 'customer-history'}))
        let newCondFilter = {
            ...condFilter,
            filterFrom: "",
            filterSkip: 0,
            filterSize: condFilter.filterSkip + condFilter.filterSize
        }
        dispatch(getListActivation(
            newCondFilter,
            false,
            callbackSuccess,
            callbackError))
        return(
            clearTimeout(timer)
        )
    },[])

    const _handleScroll = (scrollTop) => {
        if(timer) clearTimeout(timer)
        timer = setTimeout(() => {
            dispatch(setScroll({key: 'activation', scrollTop}))
        }, 500);
    }

    useScrollEnd({scrollEleRef: listRef, callback: (callback) => {
        dispatch(getListActivation(
            {
                ...condFilter,
                filterFrom: listActivation[listActivation.length - 1].id,
                filterSkip: listActivation.length,
                filterSize: 20
            }, 
        true, callback))
    }, dependencies:[listActivation], dependenciesScroll:[listActivation?.length], callBackScroll: _handleScroll})


    const _handleClickActivationItem = (id) => {
        navigate(`/${main}/${activation}/${activationDetails(id)}`)
    }

    const tableHeader = useMemo(() => (
        listActivation.length > 0 &&
            dataHeader.map((item, i) => (
                <div className="table-header" key={i}>
                    {item}
                </div>
            ))
    ), [JSON.stringify(listActivation)])

    const tableContent = useMemo(() => (
        listActivation.length === 0 ?
            <div className="wrap-empty">
                <EmptyDataControl 
                    IconNonData={<IcNodata className='ic-no-data' />}
                    labelDes='Chưa có khách hàng mới'/>
            </div>
            :
            <div className="wrap-list-trans" ref={listRef}>
                <div className="list-trans">
                {
                    listActivation.map((item, i) => (
                        <ActivationItem key={i} index={i} className='history-item'
                        item={item} refList={listRef} onClick={
                            (e) => {
                                _handleClickActivationItem(item.id)
                            }

                        }/>
                    ))
                }
                </div>
            </div>
    ), [JSON.stringify(listActivation)])

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <FilterTab />
            <WrapActivation>
                <TableHeaderActivation>
                    {tableHeader}
                </TableHeaderActivation>
                <TableContentActivation>
                    {tableContent}
                </TableContentActivation>
            </WrapActivation>
        </WrapPage>
    )
}

export default Activation