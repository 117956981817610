import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { DeltailBlockContainer } from '../../../mainStyles'

// component
import Portal from '../../../control/Portal'
import PopupPostCheck from '../../../Popup/PopupPostCheck'
import PopupConfirmPostCheck from '../../../Popup/PopupConfirmPostCheck'
import Button from '../../../control/Button'
import { apiGetAvatar, apiGetAvatarV2 } from '../../../../constant/host'

//helper
import { convertDateTime } from '../../../../helper/convertDateTime'
import { checkStatus } from '../../../../helper/checkStatus'
import numberFormatToThousand from '../../../../helper/numberFormatToThousand'

//images
import IcCheckUserWhite from '../../../../assets/images/IcCheckUserWhite'

// action
import { countTransaction } from '../../../../redux/transaction/action'
import AvatarDefault from '../../../../assets/images/AvatarDefault'

export default function DetailBlock({data}) {

    const [isErrImg, setIsErrImg] = useState(false)

    const dispatch = useDispatch()
    const {id} = useParams()

    useEffect(() => {
        setIsErrImg(false)
    }, [])

    const checkStatus = (status) => {
        switch (status) {
            case 0:
                return {
                    classNameStatus: "status red",
                    textStatus: "Thất bại",
                };
            case 1:
                return {
                    classNameStatus: "status green",
                    textStatus: "Thành công",
                };
            default:
                return {
                    classNameStatus: "status",
                    textStatus: "-",
                };
                break;
        }
    };
      

    return (
        <>
            <DeltailBlockContainer>
                <div className='detail-container'>
                    <div className='face-block'>
                        {
                            !data?.imageId ?
                            <AvatarDefault />
                            :
                            <img src={apiGetAvatarV2(data?.imageId)} alt="avatar" onError={() => setIsErrImg(true)}/>    
                        }
                    </div>
                    <div className='info-block activation-info'>
                        <div className='info-block-item'>
                            <p className='p-value' title={`${data?.customerId}`}>{data?.customerId || "-"}</p>
                            <p className='p-title'>Số CIF</p>
                        </div>
                        <div className='info-block-item'>
                        <p className='p-value' title={`${data?.deviceName}`}>{data?.deviceName || "-"}</p>
                            <p className='p-title'>Tên thiết bị</p>
                        </div>
                        <div className='info-block-item'>
                        <p className='p-value' title={`${data?.deviceOS}`}>{data?.deviceOS || "-"}</p>
                            <p className='p-title'>Hệ điều hành</p>
                        </div>
                        <div className='info-block-item'>
                            <p className='p-value'>{data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm") : "-"}</p>
                            <p className='p-title'>Thời gian kích hoạt</p>
                        </div>
                        <div className='info-block-item'>
                            <p className={checkStatus(data?.status)?.classNameStatus}>{checkStatus(data?.status)?.textStatus}</p>
                            <p className='p-title'>Trạng thái kích hoạt</p>
                        </div>
                        <div className='info-block-item'>
                            <p className='p-value' title={`${data?.statusDesc}`}>{data?.statusDesc || "-"}</p>
                            <p className='p-title'>Nguyên nhân</p>
                        </div>
                    </div>
                </div>
            </DeltailBlockContainer>
        </>
    )
}
