import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// css
import { DeltailBlockContainer } from '../../../mainStyles'

//helper
import { convertDateTime } from '../../../../helper/convertDateTime'
import { checkStatus } from '../../../../helper/checkStatus'
import { apiGetTransActivationAvatarV2 } from '../../../../constant/host'

// image
import AvatarDefault from "../../../../assets/images/AvatarDefault";

export default function ActivationDetailBlock({ data, className }) {

    const [isErrImg, setIsErrImg] = useState(false);

    useEffect(() => {
        setIsErrImg(false);
    }, []);

    let classNameStatus = "";
    let textStatus = "";
    
    switch (data?.status) {
      case 0: {
        classNameStatus = "status red";
        textStatus = "Thất bại";
        break;
      }
      case 1: {
        classNameStatus = "status light-green";
        textStatus = "Thành công";
        break;
      }
      default: {
        classNameStatus = "status"
        textStatus = "-"
      }
    }

    return (
        <DeltailBlockContainer className = {className}>
            <div className='detail-container'>
                <div className='face-block'>
                    {
                        !data?.imageId ? 
                        <AvatarDefault />
                        :
                        <img 
                            src={apiGetTransActivationAvatarV2(data?.imageId)} 
                            alt="avatar"
                            onError={() => setIsErrImg(true)}
                        />
                    }
                </div>
                <div className='info-block activation-info'>
                    {/* customerId */}
                    <div className='info-block-item'>
                        <p className='p-value' title={data?.customerId}>{data?.customerId || "-"}</p>
                        <p className='p-title'>Số CIF</p>
                    </div>
                    {/* deviceName */}
                    <div className='info-block-item'>
                        <p className='p-value' title={data?.deviceName}>{data?.deviceName || "-"}</p>
                        <p className='p-title'>Tên thiết bị</p>
                    </div>
                    {/* deviceOS */}
                    <div className='info-block-item'>
                        <p className='p-value' title={data?.deviceOS}>{data?.deviceOS || "-"}</p>
                        <p className='p-title'>Hệ điều hành</p>
                    </div>
                    {/* createAt */}
                    <div className='info-block-item'>
                        <p className='p-value'>{data?.createAt ? convertDateTime(data?.createAt, "DD/MM/YYYY - HH:mm") : ""}</p>
                        <p className='p-title'>Thời gian kích hoạt</p>
                    </div>
                    {/* status */}
                    <div className="info-block-item">
                        <p className={`p-value ${classNameStatus}`}>{textStatus}</p>
                        <p className="p-title">Trạng thái giao dịch</p>
                    </div>
                    {/* statusDesc */}
                    <div className="info-block-item reason">
                        <p className="p-value" title={data?.statusDesc}>{data?.statusDesc || "-"}</p>
                        <p className="p-title">Nguyên nhân</p>
                    </div>
                </div>
            </div>
        </DeltailBlockContainer>
    )
}
