import React, { useRef, useMemo, useEffect } from 'react'
import { useParams , useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// css
import { WrapCustomerDetail, TableHeaderCustomerHistory, TableContentCustomerHistory } from './index.styles'
import { WrapPage, TitleSessionContainer } from '../../mainStyles'

// component
import Breadcrumb from '../../control/Breadcrumb'
import Hero from '../../control/Hero'
import DetailBlock from './DetailBlock'
import EmptyDataControl from '../../control/EmptyDataControl'
import ActivationHistoryItem from './ActivationHistoryItem'
import useScrollEnd from '../../../hook/useScrollEnd'

// image 
import IcNodata from '../../../assets/images/IcNoData'

// action
import { enableLoading, disableLoading } from '../../../redux/loading/action'
import { setScroll } from '../../../redux/scrolling/action'
import { getDetailActivation, clearDetailActivation } from '../../../redux/activation/action'

const dataHeader = [
    "STT",
    "Số CIF",
    "Hình ảnh kích hoạt",
    "Thời gian kích hoạt",
    "Tên thiết bị",
    "Hệ điều hành",
    "Trạng thái kích hoạt",
    "Nguyên nhân"
]

let timer = null

const ActivationDetails = () => {

    const listRef = useRef(null)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    
    const { detailActivation } = useSelector(state => state.activation)

    useEffect(() => {
        dispatch(getDetailActivation(id))
        return () => {
            clearData()
        }
    },[])    

    const clearData = () => {
        dispatch(clearDetailActivation())
    }

    const tableHeader = useMemo(() => (
        detailActivation?.elements?.length > 0 &&
            dataHeader.map((item, i) => (
                <div className="table-header" key={i}>
                    {item}
                </div>
            ))
    ), [JSON.stringify(detailActivation?.elements)])


    const tableContent = useMemo(() => (
        detailActivation?.elements?.length === 0 ?
            <div className="wrap-empty">
                <EmptyDataControl 
                    IconNonData={<IcNodata className='ic-no-data' />}
                    labelDes='Khách hàng chưa phát sinh giao dịch'/>
            </div>
            :
            <div className="wrap-list-trans" ref={listRef}>
                <div className="list-trans">
                {
                    detailActivation?.elements?.map((item, i) => (
                    <ActivationHistoryItem key={i} index={i} className='history-item'
                        item={item} refList={listRef} />
                    ))
                }
                </div>
            </div>
    ), [JSON.stringify(detailActivation?.elements)])

    return (
        <WrapPage>
            <Breadcrumb />
            <Hero />
            <WrapCustomerDetail>
                <TitleSessionContainer>
                    <p className='title'>Thông tin kích hoạt</p>
                </TitleSessionContainer>
                <DetailBlock data={detailActivation?.details} />
                <TitleSessionContainer>
                    <p className='title' style={{marginTop: "24px"}}>Lịch sử kích hoạt</p>
                </TitleSessionContainer>
                <TableHeaderCustomerHistory>
                    {tableHeader}
                </TableHeaderCustomerHistory>
                <TableContentCustomerHistory>
                    {tableContent}
                </TableContentCustomerHistory>
            </WrapCustomerDetail>
        </WrapPage>
    )
}

export default ActivationDetails