import * as types from "./types";
import moment from "moment";

//data
import { listStatusType } from "../../data/dataActivationStatus";
import { LOGOUT } from "../login/types";

const defaultStartDate = moment().utc().startOf("month").toDate();
const defaultEndDate = moment(new Date()).endOf("day").toDate();

const initialState = {
  listActivation: [],
  detailActivation: {},
  totalActivation: 0,
  condFilter: {
    cursorTimestamp: defaultEndDate,
    startTime: defaultStartDate,
    endTime: defaultEndDate,
    status: listStatusType[0].key,
    customerId: "",
    filterFrom: "",
    filterSize: 20,
    filterSkip: 0,
  },
};

const activation = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LIST_ACTIVATION:
        return {
            ...state,
            ...action.payload,
            listActivation: action.payload.isScrolling ? [...state.listActivation, ...action.payload.listActivation] : action.payload.listActivation,
        };
    case types.CLEAR_LIST_ACTIVATION:
        return {
            ...state,
            listActivation: [],
            condFilter: initialState.condFilter
        };
    case types.GET_DETAIL_ACTIVATION:
        return {
            ...state,
            detailActivation: action.detailActivation,
        };
    case types.CLEAR_DETAIL_ACTIVATION:
        return {
            ...state,
            detailActivation: {}
        };
    case types.RESET_CONDFILTER_ACTIVATION:
        return {
            ...state,
            condFilter: initialState.condFilter
        };
    case LOGOUT:
        return initialState
        
    default:
      return state;
  }
};

export default activation;
