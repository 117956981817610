import { combineReducers } from "redux";
import notifies from './notifies'
import loading from './loading'
import login from './login'
import postcheck from "./postcheck";
import activation from "./activation/activation";
import transaction from "./transaction";
import scrolling from "./scrolling";

const app = combineReducers({
    loading,
    login,
    postcheck,
    activation,
    transaction,
    notifies,
    scrolling
});

const rootReducer = (state, action) => {
    return app(state, action);
};

export default rootReducer;