import styled from "styled-components";

const WrapFace = styled.div`
    position: relative;
    height: 40px;
    width: 40px;
    overflow: unset !important;
    img{
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
    .wrap-face-zoom{
        z-index: 10;
        position: absolute;
        left: calc(100% + 12px);
        bottom: ${props => props.propsPopup ? props.propsPopup : "-235px"};
        width: 269px !important;
        height: 269px !important;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px solid #FFFFFF;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
        border-radius: unset;
    }
`

export {WrapFace};