import styled from 'styled-components'

import { WrapPageItem, TableHeader, TableContent } from '../../mainStyles'

const WrapActivation = styled(WrapPageItem)`
    
`;

const TableHeaderActivation = styled(TableHeader)`
    grid-template-columns: 
        minmax(32px, 58px)
        minmax(96px, 122px) 
        minmax(120px, 146px)
        minmax(136px, 162px) 
        minmax(112px, 138px) 
        minmax(112px, 138px) 
        minmax(128px, 154px)
        1fr;
`;

const TableContentActivation = styled(TableContent)`
    
`;

export {
    WrapActivation,
    TableHeaderActivation,
    TableContentActivation
}