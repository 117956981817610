const main = "main"
const postCheck = "post-check"
const activation = "activation"
const transactionHistory = "transaction-history"
const customerDetails = (customerId=':customerId') => `customer-details/${customerId}`
const activationDetails = (id=':id') => `details/${id}`
const historyDetails = (historyId=':historyId') => `transaction-history-details/${historyId}`


export {
    main,
    postCheck,
    activation,
    transactionHistory,
    customerDetails,
    activationDetails,
    historyDetails
}