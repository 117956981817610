import React from "react";

// styles
import { Bound } from "./index.styles";

// help
import { convertDateTime } from "../../../../helper/convertDateTime";

// image
import { checkTypeTransaction } from "../../../../helper/checkTypeTransaction";

export default function TransactionItem(props) {
  const { className, item, refList, index , onClick} = props;

  let classNameStatus = "";
  let textStatus = "";
  switch (item?.status) {
    case 0:
      classNameStatus = "status orange";
      textStatus = "Thất bại";
      break;
    case 1:
      classNameStatus = "status green";
      textStatus = "Thành công";
      break;
  }

  return (
    <Bound  className={className} onClick={onClick}>
      <div className="item-check-nomal">
        {index < 9 ? `0${index + 1}` : index + 1}
      </div>
      <div className="item-check-nomal" title={item?.customerId}>
        {item?.customerId ? item?.customerId : "-"}
      </div>
      <div className="item-check-nomal" title={item?.transactionId}>
        #{item?.transactionId ? item?.transactionId : "-"}
      </div>
      <div className="item-trans-time">
          {convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm") ? convertDateTime(item?.createAt, "DD/MM/YYYY - HH:mm"): "-"}
      </div>
      <div className="item-trans-type">
        {checkTypeTransaction(item?.type)?.icon}
        <div>
          {checkTypeTransaction(item?.type)?.textType}
        </div>
      </div>
      <div className="item-check-nomal two-line-text" title={item?.deviceType} >
        {item?.deviceType ? item?.deviceType : "-"}
      </div>
      <div className={classNameStatus}>{textStatus}</div>
      <div className="item-check-nomal two-line-text" 
        // title={item?.statusCode !== 0 && dataActivationReason[item?.statusCode] ? dataActivationReason[item?.statusCode] : "-"}
        title={item?.statusDesc} >
        {/* {item?.statusCode !== 0 && dataActivationReason[item?.statusCode] ? dataActivationReason[item?.statusCode] : "-"} */}
        {item?.statusDesc ? item?.statusDesc : "-"}
      </div>
    </Bound>
  );
}
