import React, { useRef, useState } from 'react'

// styles
import { WrapPopupPostCheck } from './index.styles'
import { OverlayFullScreen } from '../../mainStyles'

//component
import Button from '../../control/Button'
import CheckBoxControl from '../../control/CheckBoxControl'
import TextArea from '../../control/TextAreaControl'

//images
import IcUserConfirm from '../../../assets/images/IcUserConfirm'
import IcClose from '../../../assets/images/IcClose'

//hook
import { useOnClickOutside } from '../../../hook/useClickOutside'

const listCheckBox = [
    {
        key: 1,
        text: "Hậu kiểm thành công"
    },
    {
        key: 2,
        text: "Hậu kiểm thất bại"
    },
    {
        key: 3,
        text: "Hậu kiểm thất bại, chờ OCB xử lý"
    }
]

const PopupPostCheck = ({ onClickIconClose, _handleSubmit, data }) => {

    const ref = useRef(null);

    const [objPostCheck, setObjPostCheck] = useState(listCheckBox[0])
    const [valueTextArea, setValueTextArea] = useState("")
    const [isNotice, setIsNotice] = useState(false)
    const [id, setId] = useState(data.id)
        
    const handleSubmit = () => {

        if (objPostCheck.key === 1){
            _handleSubmit(id,objPostCheck,valueTextArea)

        } else {
            if (valueTextArea){
                _handleSubmit(id,objPostCheck,valueTextArea)
            } else {
                setIsNotice(true)
            }
        }
    }
    const handleChange = (e) => {
        setValueTextArea(e.target.value)
        setIsNotice(false)
    }

    const _handleSetPostCheck = (val) => {
        setIsNotice(false)
        setObjPostCheck(val)
    }

    useOnClickOutside(ref, () => onClickIconClose())

    return (
        <OverlayFullScreen notUseOverlay={true}>
            <div className="wrap-inner-screen">
                <WrapPopupPostCheck ref={ref} >
                    <div className="icon-close"
                        onClick={onClickIconClose} >
                        <IcClose />
                    </div>
                    <div className="title">Hậu kiểm khách hàng</div>
                    <div className="sub-title">
                        Bạn vui lòng đánh giá chất lượng hình ảnh khách hàng
                    </div>
                    <div className='reason-list'>

                        <CheckBoxControl
                            listItem={listCheckBox}
                            valueSelected={objPostCheck}
                            setValueSelect={_handleSetPostCheck}
                        />
                        {
                            (objPostCheck.key === 2 || objPostCheck.key === 3) &&
                            <>
                                <div className='reason-text'>
                                    <TextArea
                                        placeholder='Nhập lý do hậu kiểm thất bại, Giới hạn 200 ký tự'
                                        maxLength={200}
                                        onChange={handleChange}
                                        value={valueTextArea}
                                    />
                                    {
                                        !valueTextArea &&
                                        <span className='required'></span>
                                    }
                                </div>
                                {
                                    isNotice && 
                                    <div className='reason-notice'>
                                        Vui lòng nhập lí do hậu kiểm thất bại
                                    </div>
                                }
                            </>
                        }
                    </div>
                    <div>
                        <Button
                            onClick={handleSubmit}
                            className="btn-submit"
                            text="Xác nhận"
                            iconBtn={<IcUserConfirm />}
                        />
                    </div>
                </WrapPopupPostCheck>
            </div>
        </OverlayFullScreen>
    )
}

export default PopupPostCheck
