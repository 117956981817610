import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment'

// css
import { WrapHeader } from './index.styles'

// image
import IcAvatarUser from '../../../assets/images/IcAvatarUserBlue'
import IcLogout from '../../../assets/images/IcLogout'
import LogoHeader from '../../../assets/images/LogoHeader'

// action
import { logoutAction } from '../../../redux/login/action'
import { clearListCheckPost, getListCheckPost, clearDetailCheckPost } from '../../../redux/postcheck/action'
import { clearListTransaction, clearDetailTransaction } from '../../../redux/transaction/action'

// constant
import { main, postCheck } from '../../../constant/routes'
 
const Header = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const { dataLogin, loggedIn } = useSelector(state => ({
        dataLogin: state.login.dataLogin,
        loggedIn: state.login.loggedIn
    }))

    useEffect(() => {
        if(loggedIn) return
        dispatch(clearListCheckPost())
        dispatch(clearDetailCheckPost())
        dispatch(clearListTransaction())
        dispatch(clearDetailTransaction())

        navigate('/')
    
        return () => {
            
        }
    }, [loggedIn])
    

    const callbackSuccess = () => {
        dispatch(getListCheckPost({
            startTime: moment().utc().startOf("month").toDate(),
            endTime: moment(new Date()).utc().endOf("day").toDate(),
            reviewStatus: -1,
            customerId: "",
            filterFrom: "",
            filterSize: 20,
            filterSkip: 0,
        }))
    };

    const _handleClick = () => {
        dispatch(clearListCheckPost(callbackSuccess))
    }

    const _handleLogout = () => {
        dispatch(logoutAction())
        
    }

    return (
        <WrapHeader>
            <div className="wrap-inner">
               <Link to={`/${main}/${postCheck}`} className='logo-header' onClick={_handleClick}>
                    <LogoHeader/>   
               </Link>
               <div className="user">
                    <p className="user_name">{dataLogin.username}</p>
                    <IcAvatarUser className="ic-logout"/>
                    <IcLogout className="ic-logout" onClick={_handleLogout} />
                    
                </div>

            </div>
        </WrapHeader>
    )
}

export default Header