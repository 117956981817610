export const GET_LIST_ACTIVATION = "GET_LIST_ACTIVATION"
export const GET_LIST_ACTIVATION_ERROR = "GET_LIST_ACTIVATION_ERROR"
export const CLEAR_LIST_ACTIVATION = "CLEAR_LIST_ACTIVATION"

export const GET_CUSTOMER_ACTIVATION_HISTORIES = "GET_CUSTOMER_ACTIVATION_HISTORIES"
export const GET_CUSTOMER_ACTIVATION_HISTORIES_ERROR = "GET_CUSTOMER_ACTIVATION_HISTORIES_ERROR"
export const CLEAR_CUSTOMER_ACTIVATION_HISTORIES = "CLEAR_CUSTOMER_ACTIVATION_HISTORIES"

export const GET_DETAIL_ACTIVATION = "GET_DETAIL_ACTIVATION"
export const GET_DETAIL_ACTIVATION_ERROR = "GET_DETAIL_ACTIVATION_ERROR"
export const CLEAR_DETAIL_ACTIVATION = "CLEAR_DETAIL_ACTIVATION"

export const RESET_CONDFILTER_ACTIVATION = "RESET_CONDFILTER_ACTIVATION"

export const EXPORT_LIST_ACTIVATION_ERROR = "EXPORT_LIST_ACTIVATION_ERROR"