import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    grid-template-columns:  
        minmax(32px,55px) 
        minmax(96px,120px) 
        minmax(120px,145px) 
        minmax(136px,155px) 
        minmax(115px,140px)
        minmax(120px,145px)
        minmax(130px,155px) 1fr;
    padding: 0 15px;
    grid-gap: 5px; 
    width: calc(100% - 30px);
    height: 60px;
    background: #FFFFFF;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    font-family: "Manrope";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.01em;
    color: #212123;
    align-items: center;
    cursor: pointer;
    &:hover {
        height: 58px;
        padding: 0 14px;
        border: 1px solid #008C44;
    }
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .item-check-img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-left: 22px;
        .avatar{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            &:hover {
                cursor: pointer;
            }
        }

    }
    .item-check-bold{
        font-weight: 600;
    }
    .item-check-nomal{
        gap: 6px;
        
    }
    .item-trans-type{
        display: flex;
        align-items: center;
        gap: 6px;
        
    }
    .item-trans-time{
        display: flex;
        justify-content: end;
        padding-right: 25px;
    }
    .status{
        border-radius: 8px;
        color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 10px;
        box-sizing: border-box;
        font-weight: 600;
        height: 30px;
        &.green{
            background-color: #EBF9EB;
            color: #3CC13B;
            width: 128px;
        }
        &.orange{
            background-color: #FFF1EF;
            color: #D62A00;
            width: 128px;
        }
    } 
    .two-line-text{
        white-space: unset;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export {
    Bound
}