export const listStatusTypeTransaction = [
    { text: 'Tất cả', key: -1 },
    { text: 'Thành công', key: 1 },
    { text: 'Thất bại', key: 2 },
    { text: 'Chờ xử lý', key: 0 }
]

export const listStatusTypeTransactionV2 = [
    { text: 'Tất cả', key: -1 },
    { text: 'Thành công', key: 1 },
    { text: 'Thất bại', key: 0 },
]