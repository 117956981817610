import React from 'react'

export default function IcFaceLogin(props) {
  return (
    <svg {...props} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.5723" cy="16" r="16" fill="#F1FCF6"/>
        <path d="M15.0723 19.75L18.8223 16L15.0723 12.25" stroke="#008C44" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.8223 16H9.82227" stroke="#008C44" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.8223 9.25H21.8223C22.2201 9.25 22.6016 9.40804 22.8829 9.68934C23.1642 9.97064 23.3223 10.3522 23.3223 10.75V21.25C23.3223 21.6478 23.1642 22.0294 22.8829 22.3107C22.6016 22.592 22.2201 22.75 21.8223 22.75H18.8223" stroke="#008C44" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
