import Axios from 'axios'
import * as types from './types'

import {host} from '../../constant/host'

// api
import { enableLoading, disableLoading } from '../loading/action';
import { logoutAction } from '../login/action';
import { popNotify } from '../notifies/action';

// helpper
import autoDownloadFromBlob from '../../helper/autoDownloadFromBlob';
import checkIsSuccessAction from "../../helper/checkIsSuccessAction";
import mapErrors from '../../helper/mapErrors';
import checkSessionLogin from '../../helper/checkSessionLogin';

const apiPostCheckFilter = `${host}/manage/customer/filter`
const apiPostCheckDetail = `${host}/manage/customer/details`
const apiPostCheckUpdate = `${host}/manage/customer/review`
const apiPostCheckCount = `${host}/manage/customer/count`
const apiPostCheckExport = `${host}/manage/customer/export`
const apiPostCheckUpdateFace = `${host}/manage/customer/biometric/upgrade`

const headerDataRequest = {
    cId: "WebPortal",
    reqId: `${new Date().getTime()}`,
    langCode: "vn",
};

let isCalling = false

export const getListCheckPost = (
    condFilterItem = {
        customerId: "",
        startTime: "",
        endTime: "",
        reviewStatus: "",
        filterFrom: "",
        filterSkip: 0,
        filterSize: 20
    },
    isScrolling = false,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => {

    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    if(!isScrolling) dispatch(enableLoading())
    let condFilter = {...condFilterItem}
    let dataRequest = {...headerDataRequest , data: condFilter}
    try {
        const res = await Axios.post(apiPostCheckFilter, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_LIST_CHECK_POST,
                payload: { 
                    listPostCheck: data.data.elements || [],
                    condFilter,
                    isScrolling
                }
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_LIST_CHECK_POST_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
       dispatch(mapErrors(error, types.GET_LIST_CHECK_POST_ERROR))
       callbackError && callbackError()
    }
    if(!isScrolling) setTimeout(() => {
        dispatch(disableLoading())
    }, 500); 
    isCalling = false
}

export const clearListCheckPost = (callbackSuccess) => async (dispatch) => {
    dispatch({type: types.CLEAR_LIST_CHECK_POST})
    callbackSuccess && callbackSuccess()

}


export const getDetailCheckPost = (
    customerId,
    callbackSuccess,
    callbackError,
    notCheckSessionLogin = false
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!notCheckSessionLogin){
        if(!checkSessionLogin(dataLogin)) {
            dispatch(logoutAction())
            return
        }
    }
    if(isCalling) return
    isCalling = true
    // dispatch(enableLoading())

    let data = {...customerId}

    let dataRequest = {...headerDataRequest, data}
    
    try {
        const res = await Axios.post(apiPostCheckDetail, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.GET_DETAIL_CHECK_POST,
                detailPostCheck: data.data.element 
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.GET_DETAIL_CHECK_POST_ERROR,
                payload: {
                    ...data
                }
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.GET_DETAIL_CHECK_POST_ERROR))
        callbackError && callbackError()
    }
    // setTimeout(() => {
    //     dispatch(disableLoading())
    // }, 500); 
    isCalling = false
}

export const clearDetailCheckPost = () => async (dispatch) => {
    dispatch({type: types.CLEAR_DETAIL_CHECK_POST})
}


export const resetListCheckPost = (callbackSuccess) => async dispatch => {
    dispatch({
      type: types.RESET_CONDFILTER,
    })
  
    callbackSuccess && callbackSuccess()
}

export const updatePostCheck = (
    dataPostCheck = {
        id: "",
        reviewStatus: "",
        reviewDesc: ""
    },
    callbackSuccess,
    callbackError,
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) {
        dispatch(logoutAction())
        return
    }
    let data = {...dataPostCheck}
    let dataRequest = {...headerDataRequest, data}
    dispatch(enableLoading())

    try {
        const res = await Axios.post(apiPostCheckUpdate, dataRequest)
        const {data} = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.UPDATE_CHECK_POST_SUCCESS,
                payload: data.data
            })
            // dispatch(popNotify())
            callbackSuccess && callbackSuccess()
        }
        else{
            dispatch({
                type: types.UPDATE_CHECK_POST_ERROR,
                payload: data.data
            })
            callbackError && callbackError()
        }

    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_CHECK_POST_ERROR))
        callbackError && callbackError()
    }
    setTimeout(() => {
        dispatch(disableLoading())
    }, 500);

}


export const countPostCheck = (
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) return

    let dataRequest = {...headerDataRequest}

    try {
        const res = await Axios.post(apiPostCheckCount, dataRequest)
        const {data} = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.COUNT_LIST_POST_CHECK,
                totalPostCheck: data.data.total
            })
         callbackSuccess && callbackSuccess()
        }else {
            dispatch({type: types.COUNT_LIST_POST_CHECK_ERROR})
            callbackError && callbackError()
        }
        
    } catch (error) {
       dispatch(mapErrors(error, types.COUNT_LIST_POST_CHECK_ERROR))
       callbackError && callbackError()
    }
}

export const exportPostCheck = (
    dataPostCheck = {
        customerId: "",
        reviewStatus: "",
        startTime: "",
        endTime: ""
    },
    callbackSuccess,
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) {
        dispatch(logoutAction())
        return
    }
    let data = {...dataPostCheck}
    let dataRequest = {...headerDataRequest, data}

    try {
        const res = await Axios.post(apiPostCheckExport, dataRequest, {
            responseType: 'blob',
        })
        const {data} = res
        autoDownloadFromBlob(data, 'postcheck')
        
    } catch (error) {
        dispatch(mapErrors(error, types.EXPORT_LIST_POST_CHECK_ERROR))
    }
}

export const updateFacePostCheck = (
    dataPostCheck = {
        srcId: "",
        destId: ""
    },
    callbackSuccess,
    callbackError
) => async (dispatch, getState) => {
    let dataLogin = getState().login.dataLogin
    if(!checkSessionLogin(dataLogin)) {
        dispatch(logoutAction())
        return
    }
    let data = {...dataPostCheck}
    let dataRequest = {...headerDataRequest, data}
    dispatch(enableLoading())
    try {
        const res = await Axios.post(apiPostCheckUpdateFace, dataRequest)
        const { data } = res

        if(checkIsSuccessAction(data.data.code)){
            dispatch({
                type: types.UPDATE_FACE_POST_CHECK_SUCCESS,
                payload: data.data
            })
            callbackSuccess && callbackSuccess()
        }else{
            dispatch({
                type: types.UPDATE_FACE_POST_CHECK_ERROR
            })
            callbackError && callbackError()
        }
        
    } catch (error) {
        dispatch(mapErrors(error, types.UPDATE_FACE_POST_CHECK_ERROR))
        callbackError && callbackError()
    }
    setTimeout(() => {
        dispatch(disableLoading())
    }, 500);
}